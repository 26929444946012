import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/">
        <img src="logo.png" alt="logo.png" />
      </Link>
      <div className="links">
        <Link to="/">Home</Link>
        <Link to="/services">Services</Link>
        <Link to="/about">About</Link>
        <Link
          to="/contact"
          style={{
            color: "white",
            backgroundColor: "#f1356d",
            borderRadius: "0.2rem",
          }}
        >
          Contact
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
