import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div>
      <div class="itservice">
        <h2>
          <img src="software-icon.png" alt="software Development"></img>Software
          Development
        </h2>
        <p>
          With solutions developed primarily for finance sector, we provide
          services in analysis, design, development, testing and implementation
          of various applications with popular programming languages and
          technologies. <Link to="/services">Read more</Link>
        </p>
      </div>
      <div class="itservice">
        <h2>
          <img src="consultancy-icon.png" alt="software Development"></img>
          Consultancy
        </h2>
        <p>
          Efficient use of Information Technologies is one of the most important
          elements for institutionsto ensure and maintain their competitive edge
          in domestic and international markets, act fast and quickly adapt to
          changing conditions. <Link to="/services">Read more</Link>
        </p>
      </div>
      <div class="itservice">
        <h2>
          <img src="outsourcing-icon.png" alt="software Development"></img>{" "}
          Outsourcing
        </h2>
        <p>
          With our different service models we provide on your behalf
          operational efficiency for your information technology operations with
          our expert teams. We enable our clients to focus on fields with high
          professional competences. <Link to="/services">Read more</Link>
        </p>
      </div>
    </div>
  );
};

export default Home;
