const Services = () => {
  return (
    <div>
      <div class="itservice">
        <h2>Software Development</h2>
        <img
          src="gelistirme.jpg"
          alt="software Development"
          class="responsive"
        ></img>
        <p>
          Don't you think it's time to use modern software which is design for
          your requirements in the ever-growing technology world? In the
          business world, where the competitive environment is increasing,
          companies that use technology correctly and efficiently are now
          increasing their profitability and efficiency more. Thanks to the
          blessings of technology, it can take your brands one step further and
          expands your market share.
        </p>
        <p>
          We, as Easy Solution Info Tech Limited, produce software solutions
          that provide full efficiency and usability for your company with
          popular programming languages for instance C#, java, javascript,
          databases (Oracle, Ms Sql Server). We increase your in-house
          productivity. we produce the small, medium or large-scale software
          application solutions with services in analysis, design, development,
          testing and implementation by using popular programming languages and
          cutting-edge technologies and make your work easier and move you
          further.
        </p>
      </div>
      <div class="itservice">
        <h2>Consultancy</h2>
        <img src="consultancy.jpg" alt="Consultancy" class="responsive"></img>
        <p>
          Efficient use of Information Technologies is one of the most important
          elements for companies ensure and maintain their competitive edge in
          domestic and international markets, act fast and quickly adapt to
          changing conditions. This is challenging. Making lots of decisions,
          choosing correct solution are important. Most of time, you need to
          experts to achieve these.
        </p>
        <p>
          To help the companies, we give Consultancy about
          <ul>
            <li>
              Software design and development with modern and popular
              programming languages
            </li>
            <li>unit and automation tests</li>
            <li>cloud base application solutions and AWS or Azure</li>
            <li>
              Sql or NoSql database solutions such as Oracle, Ms SqlServer,
              MongoDb
            </li>
            <li>
              migration form legacy system to modern and cost efficient software
              solutions.
            </li>
          </ul>
        </p>
      </div>
      <div class="itservice">
        <h2>Outsourcing</h2>
        <p>
          With our different service models we provide on your behalf
          operational efficiency for your information technology operations with
          our expert teams. We enable our clients to focus on fields with high
          professional competences.
        </p>
        <ul>
          <li>Reduce costs and increase profitability</li>
          <li>Meet the periodic labor requirements</li>
          <li>Offer different service options</li>
          <li>Control risks by increasing sustainability</li>
          <li>Increase of the service quality</li>
          <li>Effective use of sources</li>
          <li>Offer innovative solutions</li>
          <li> Wide and flexible resource pool</li>
        </ul>
      </div>
    </div>
  );
};

export default Services;
