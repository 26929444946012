const About = () => {
  return (
    <div class="itservice">
      <h2>who we are?</h2>
      <p>
        Easy Solution Information Technology is established in 2019 in order to
        provide information solutions to all sectors especially to finance
        sector. It aims developing solutions that uses updated technologies by
        analyzing the needs of its customers. Provides technology consultancy by
        proposing projects in accordance with business objectives. In line with
        customer’s targets, contributes to their success by providing
        maintenance support in the life cycle of advanced technological
        applications.
      </p>
      <p>
        Since the first day it was founded, it has been giving direction to its
        work with its professional approach, keeping customer satisfaction in
        first priority by doing all its work with great care and care and also
        while following the current technology and developments closely, it
        constantly improves itself.
      </p>
    </div>
  );
};

export default About;
