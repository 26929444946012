import "./Contact.css";
const Contact = () => {
  return (
    <div class="itservice">
      <p>Contact us and we'll get back to you within 24 hours.</p>
      <h3>Mail:</h3>
      <p>
        <mailto>easysolutioninfotech@gmail.com</mailto>
      </p>

      <h3>Phone:</h3>
      <p>+44 7493 494985</p>
      <h3>Address:</h3>
      <address>
        21 East Street <br />
        Bromley <br />
        BR1 1QE <br />
        United Kingdom
      </address>
    </div>
  );
};
export default Contact;
